import Phaser from 'phaser';

import LoadScene from "./scenes/LoadScene";
import MenuScene from "./scenes/MenuScene";
import PlayerScene from "./scenes/PlayerScene";
import PlayScene from "./scenes/PlayScene";
import WinScene from "./scenes/WinScene";


export const GAME_LENGTH = 12;
export const TIERS = [378, 200];

export const CONFIG = {
    width: 800,
    height: 460,
    scene: [
        LoadScene, MenuScene, PlayerScene, PlayScene, WinScene
    ],
    render: {
        pixelArt: false
    },
    physics: {
        default: "arcade",
        arcade: {
            debug: false
        }
    },
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
    }
};

export const SCENES = {
    LOAD: "LOAD",
    MENU: "MENU",
    PLAY: "PLAY",
    PLAYER: "PLAYER",
    WIN: "WIN",
};

export const IMAGES = {
    BACKGROUND: {
        key: "background",
        url: "background.png",
    },
    PLAY: {
        key: "play_button",
        url: "play_button.png",
    },
    WOODCUTTER: {
        key: "woodcutter",
        url: "Woodcutter.png",
    },
    STEAM_MAN: {
        key: "steam_man",
        url: "SteamMan.png",
    },
    GRAVE_ROBBER: {
        key: "grave_robber",
        url: "GraveRobber.png",
    },
    UPPER_TILE: {
        key: "upper_tile",
        url: "upper_tile.png",
    },
    LOWER_TILE: {
        key: "lower_tile",
        url: "lower_tile.png",
    },
    TREE1: {
        key: "tree1",
        url: "Tree1.png",
    },
    TREE2: {
        key: "tree2",
        url: "Tree2.png",
    },
    TREE3: {
        key: "tree3",
        url: "Tree3.png",
    },
    TREE4: {
        key: "tree4",
        url: "Tree4.png",
    },
    POINTER: {
        key: "pointer",
        url: "pointer.png",
    },
    BOX: {
        key: "box",
        url: "box.png",
    },
    SPARK: {
        key: "spark",
        url: "blue.png",
    },
    UNDERSEA: {
        key: "undersea",
        url: "undersea.jpg",
    },
    QUEST: {
        key: "quest",
        url: "quest.png",
    },
};

export const AUDIOS = {
    POSITIVE: {
        key: "positive",
        url: "positive.mp3"
    },
    NEGATIVE: {
        key: "negative",
        url: "negative.mp3"
    },
};

export const PLAYERS = {
    // Players
    WOODCUTTER: [
        {
            key: "woodcutter_run",
            url: "woodcutter/Woodcutter_run.png",
            frameConfig: {
                frameWidth: 28,
                frameHeight: 48,
                spacing: 20,
            }
        },
        {
            key: "woodcutter_attack",
            url: "woodcutter/Woodcutter_attack.png",
            frameConfig: {
                frameWidth: 28,
                frameHeight: 48,
                spacing: 20,
            }
        },
        {
            key: "woodcutter_jump",
            url: "woodcutter/Woodcutter_jump.png",
            frameConfig: {
                frameWidth: 28,
                frameHeight: 48,
                spacing: 20,
            }
        },
        {
            key: "woodcutter_hurt",
            url: "woodcutter/Woodcutter_hurt.png",
            frameConfig: {
                frameWidth: 28,
                frameHeight: 48,
                spacing: 20,
            }
        },
        {
            key: "woodcutter_death",
            url: "woodcutter/woodcutter_death.png",
            frameConfig: {
                frameWidth: 28,
                frameHeight: 48,
                spacing: 20,
            }
        },
    ],
    GRAVE_ROBBER: [
        {
            key: "grave_robber_run",
            url: "GraveRobber/GraveRobber_run.png",
            frameConfig: {
                frameWidth: 30,
                frameHeight: 48,
                spacing: 18,
            }
        },
        {
            key: "grave_robber_attack",
            url: "GraveRobber/GraveRobber_attack.png",
            frameConfig: {
                frameWidth: 30,
                frameHeight: 48,
                spacing: 18,
            }
        },
        {
            key: "grave_robber_jump",
            url: "GraveRobber/GraveRobber_jump.png",
            frameConfig: {
                frameWidth: 30,
                frameHeight: 48,
                spacing: 18,
            }
        },
        {
            key: "grave_robber_hurt",
            url: "GraveRobber/GraveRobber_hurt.png",
            frameConfig: {
                frameWidth: 30,
                frameHeight: 48,
                spacing: 18,
            }
        },
        {
            key: "grave_robber_death",
            url: "GraveRobber/GraveRobber_death.png",
            frameConfig: {
                frameWidth: 30,
                frameHeight: 48,
                spacing: 18,
            }
        },
    ],
    STEAM_MAN: [
        {
            key: "steam_man_run",
            url: "SteamMan/SteamMan_run.png",
            frameConfig: {
                frameWidth: 32,
                frameHeight: 48,
                spacing: 16,
            }
        },
        {
            key: "steam_man_attack",
            url: "SteamMan/SteamMan_attack.png",
            frameConfig: {
                frameWidth: 32,
                frameHeight: 48,
                spacing: 16,
            }
        },
        {
            key: "steam_man_jump",
            url: "SteamMan/SteamMan_jump.png",
            frameConfig: {
                frameWidth: 32,
                frameHeight: 48,
                spacing: 16,
            }
        },
        {
            key: "steam_man_hurt",
            url: "SteamMan/SteamMan_hurt.png",
            frameConfig: {
                frameWidth: 32,
                frameHeight: 48,
                spacing: 16,
            }
        },
        {
            key: "steam_man_death",
            url: "SteamMan/SteamMan_death.png",
            frameConfig: {
                frameWidth: 32,
                frameHeight: 48,
                spacing: 16,
            }
        },
    ],
};

export const ENEMIES = {
    //Enemies
    BEHOLDER: [
        {
            key: "beholder_attack",
            url: "Beholder/Beholder_attack.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "beholder_walk",
            url: "Beholder/Beholder_walk.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "beholder_hurt",
            url: "Beholder/Beholder_hurt.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "beholder_death",
            url: "Beholder/Beholder_death.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
    ],
    GOBLIN: [
        {
            key: "goblin_attack",
            url: "DarkGoblin/DarkGoblin_attack.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "goblin_walk",
            url: "DarkGoblin/DarkGoblin_walk.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "goblin_hurt",
            url: "DarkGoblin/DarkGoblin_hurt.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "goblin_death",
            url: "DarkGoblin/DarkGoblin_death.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
    ],
    HOUND: [
        {
            key: "hound_attack",
            url: "Hellhound/Hellhound_attack.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "hound_walk",
            url: "Hellhound/Hellhound_walk.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "hound_hurt",
            url: "Hellhound/Hellhound_hurt.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "hound_death",
            url: "Hellhound/Hellhound_death.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
    ],
    HUSKY: [
        {
            key: "husky_attack",
            url: "Husky/Husky_attack.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "husky_walk",
            url: "Husky/Husky_walk.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "husky_hurt",
            url: "Husky/Husky_hurt.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "husky_death",
            url: "Husky/Husky_death.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
    ],
    SKELETON: [
        {
            key: "skeleton_attack",
            url: "Skeleton/Skeleton_attack.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "skeleton_walk",
            url: "Skeleton/Skeleton_walk.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "skeleton_hurt",
            url: "Skeleton/Skeleton_hurt.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "skeleton_death",
            url: "Skeleton/Skeleton_death.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
    ],
    ZOMBIE: [
        {
            key: "zombie_attack",
            url: "Zombie/Zombie_attack.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "zombie_walk",
            url: "Zombie/Zombie_walk.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "zombie_hurt",
            url: "Zombie/Zombie_hurt.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
        {
            key: "zombie_death",
            url: "Zombie/Zombie_death.png",
            frameConfig: {
                frameWidth: 42,
                frameHeight: 42,
            }
        },
    ],
}

export const SPRITES = {
    COIN: {
        key: "coin",
        url: "coin.png",
        frameConfig: {
            frameHeight: 32,
            frameWidth: 26,
            spacing: 6,
        },
        end: 7,
    },
    FLAG: {
        key: "flag",
        url: "flag.png",
        frameConfig: {
            frameHeight: 32,
            frameWidth: 32,
        },
        end: 7,
    },
    GOLD: {
        key: "gold",
        url: "gold.png",
        frameConfig: {
            frameHeight: 24,
            frameWidth: 32,
        },
        end: 7,
    },
}

export const UI_OBJECTS = [
    {
        name: "tree1",
        tier: 0,
        offset: -55,
    },
    {
        name: "tree2",
        tier: 0,
        offset: -55,
    },
    {
        name: "tree3",
        tier: 0,
        offset: -22,
    },
    {
        name: "tree4",
        tier: 0,
        offset: -55,
    },
]

export const BOOST_OBJECTS = [
    {
        name: "coin",
        tier: 0,
        widthOffset: 6,
        heightOffset: 6,
        heightAdjustSize: 8,
        offset: -10,
    },
    {
        name: "coin",
        tier: 1,
        widthOffset: 6,
        heightOffset: 6,
        heightAdjustSize: 8,
        offset: 0,
    },
    {
        name: "gold",
        tier: 0,
        widthOffset: 5,
        heightOffset: 0,
        heightAdjustSize: 0,
        offset: 18,
    },
]

export const ENEMY_OBJECTS = [
    {
        name: "beholder",
        widthOffset: 8,
        heightOffset: 8,
        tier: 1,
    },
    {
        name: "beholder",
        widthOffset: 8,
        heightOffset: 8,
        tier: 0,
    },
    {
        name: "goblin",
        widthOffset: 12,
        heightOffset: 10,
        tier: 0,
    },
    {
        name: "hound",
        widthOffset: 6,
        heightOffset: 11,
        tier: 0,
    },
    {
        name: "husky",
        widthOffset: 7,
        heightOffset: 9,
        tier: 0,
    },
    {
        name: "skeleton",
        widthOffset: 10,
        heightOffset: 8,
        tier: 0,
    },
    {
        name: "zombie",
        widthOffset: 11,
        heightOffset: 8,
        tier: 0,
    }
]

export const PLAYER_OBJECTS = {
    "woodcutter": {
        widthOffset: 2,
        heightOffset: 9,
    },
    "grave_robber": {
        widthOffset: 5,
        heightOffset: 10,
    },
    "steam_man": {
        widthOffset: 2,
        heightOffset: 10,
    },
}

export const WIN_NUMBERS = [
    28894, 16430, 47136, 46039, 62912, 57509, 33487, 82033, 94596, 52881, 81612, 29224, 55255, 75308, 37315, 19910,
    37599, 30537, 95502, 93465, 74569, 48948, 66138, 12834, 43280, 67567, 80401, 96410, 73379, 13627, 62182, 82441,
    98210, 52327, 72852, 73705, 71341, 37930, 22687, 43669, 13415, 31007, 75095, 43714, 77303, 93381, 83044, 90031,
    67561, 79306, 35402, 61626, 34258, 95699, 65256, 29851, 59310, 61274, 82813, 87209, 76402, 87550, 30970, 37690,
    91396, 56207, 77710, 52645, 76442, 21486, 22747, 29658, 31242, 34512, 50815, 94697, 61258, 46180, 43921, 37943,
    37318, 52322, 14280, 48777, 74064, 87980, 16287, 71027, 29404, 34660, 50779, 80257, 38954, 30913, 99299, 12704,
    91964, 16947, 97617, 83923, 28193, 42692, 87783, 46370, 88764, 47963, 20640, 38044, 21482, 83200, 94078, 68812,
    77984, 46294, 61635, 55053, 46437, 54123, 31614, 46624, 28151, 13383, 76228, 48644, 11732, 26190, 61383, 90597,
    24138, 50743, 90408, 25964, 77715, 91600, 96106, 23430, 41364, 95590, 14913, 80233, 31486, 33633, 34643, 16114,
    77406, 22446, 23381, 70277, 66815, 94361, 21470, 68324, 30291, 69402, 11272, 62294, 58099, 54691, 48200, 41513,
    68227, 58833, 41485, 53662, 39857, 85418, 14197, 20051, 35595, 82351, 60318, 98577, 92076, 94643, 52157, 86859,
    17747, 97573, 34153, 21972, 76217, 96891, 57912, 44455, 31640, 84266, 79411, 63741, 44448, 62617, 52571, 78969,
    31534, 29667, 91685, 84166, 33566, 40122, 94018, 98040
]
