import Phaser from 'phaser';

import { SCENES, IMAGES, ENEMIES, SPRITES } from "../constants";


export class PlayerScene extends Phaser.Scene {
    constructor() {
        super({
            key: SCENES.PLAYER
        })
    }
    preload() {
        // Animations of enemies
        Object.keys(SPRITES).forEach(spriteName => {
            const sprite = spriteName.toLowerCase();
            this.anims.create({
                key: sprite,
                frames: this.anims.generateFrameNumbers(sprite, { start: 0, end: SPRITES[spriteName].end }),
                repeat: -1,
                frameRate: 4
            });
        });

        // Animations of enemies
        Object.keys(ENEMIES).forEach(enemyName => {
            const enemy = enemyName.toLowerCase();
            this.anims.create({
                key: enemy + "_attack",
                frames: this.anims.generateFrameNumbers(enemy + "_attack", { start: 0, end: 3 }),
                repeat: -1,
                frameRate: 4
            });
            this.anims.create({
                key: enemy + "_walk",
                frames: this.anims.generateFrameNumbers(enemy + "_walk", { start: 0, end: 3 }),
                repeat: -1,
                frameRate: 4
            });
            this.anims.create({
                key: enemy + "_hurt",
                frames: this.anims.generateFrameNumbers(enemy + "_hurt", { start: 0, end: 3 }),
                repeat: -1,
                frameRate: 4
            });
            this.anims.create({
                key: enemy + "_death",
                frames: this.anims.generateFrameNumbers(enemy + "_death", { start: 0, end: 3 }),
                repeat: 0,
            });
        });
    }
    create() {
        this.background = this.add.image(0, 0, IMAGES.UNDERSEA.key).setDepth(0);
        this.background.setOrigin(0, 0);
        this.background.scaleX = 1.2;
        this.background.scaleY = 0.8;

        const woodCutter = this.add.image(this.game.renderer.width / 5, this.game.renderer.height / 2, IMAGES.WOODCUTTER.key).setDepth(1);
        const steamMan = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, IMAGES.STEAM_MAN.key).setDepth(1);
        const graveRobber = this.add.image(this.game.renderer.width * 4 / 5, this.game.renderer.height / 2, IMAGES.GRAVE_ROBBER.key).setDepth(1);

        woodCutter.setInteractive();
        steamMan.setInteractive();
        graveRobber.setInteractive();

        woodCutter.on("pointerup", () => {
            this.scene.start(SCENES.PLAY, {player: IMAGES.WOODCUTTER.key});
        })
        
        steamMan.on("pointerup", () => {
            this.scene.start(SCENES.PLAY, {player: IMAGES.STEAM_MAN.key});
        })
        
        graveRobber.on("pointerup", () => {
            this.scene.start(SCENES.PLAY, {player: IMAGES.GRAVE_ROBBER.key});
        })

        this.add.text(this.game.renderer.width/5-80, this.game.config.height/2+40, "Woodcutter", {
                fontSize: '27px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );
        this.add.text(this.game.renderer.width/2-80, this.game.config.height/2+40, "Steam Man", {
                fontSize: '27px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );
        this.add.text(this.game.renderer.width*4/5-80, this.game.config.height/2+40, "Grave Robber", {
                fontSize: '27px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );

        this.add.text(this.game.renderer.width/2-80, this.game.config.height/7, "PLAYERS", {
                fontSize: '35px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );

        this.add.text(this.game.renderer.width/2-115, this.game.config.height/7 + 50, "click on any player to start the game.", {
                fontSize: '14px',
                fontFamily: 'Arial',
                color: '#000',
            }
        );

    }
}

export default PlayerScene;
