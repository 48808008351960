import Phaser from 'phaser';

import { SCENES, IMAGES, AUDIOS, SPRITES, ENEMIES, PLAYERS } from "../constants";


class LoadScene extends Phaser.Scene {
    constructor() {
        super({
            key: SCENES.LOAD
        })
    }
    
    init() {

    }
    
    loadImages() {
        this.load.setPath("./assets/image/");

        for (let prop in IMAGES) {
            const image = IMAGES[prop];
            this.load.image(image.key, image.url);
        }
    }

    loadAudio() {
        this.load.setPath("./assets/audio/");

        for (let prop in AUDIOS) {
            const audio = AUDIOS[prop];
            this.load.audio(audio.key, audio.url);
        }
    }

    loadSprites() {
        this.load.setPath("./assets/sprite/");

        for (let prop in SPRITES) {
            const sprite = SPRITES[prop];
            this.load.spritesheet(sprite.key, sprite.url, sprite.frameConfig);
        }
        for (let prop in PLAYERS) {
            for (let sprite of PLAYERS[prop]) {
                this.load.spritesheet(sprite.key, sprite.url, sprite.frameConfig);
            }
        }
        for (let prop in ENEMIES) {
            for (let sprite of ENEMIES[prop]) {
                this.load.spritesheet(sprite.key, sprite.url, sprite.frameConfig);
            }
        }
    }

    preload() {
        this.loadAudio();
        this.loadImages();
        this.loadSprites();

        let loadingBar = this.add.graphics({
            fillStyle: {
                color: 0xffffff //white
            }
        });

        this.load.on("progress", (percent) => {
            loadingBar.fillRect(this.game.renderer.width * percent, 250, 50, 20);
        });

        this.load.on("complete", () => {
            setTimeout(() => {
                this.scene.start(SCENES.MENU);
            }, 1000);
        });
    }
}

export default LoadScene;
