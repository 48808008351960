import Phaser from 'phaser';

import { SCENES, IMAGES, WIN_NUMBERS } from "../constants";


export class WinScene extends Phaser.Scene {
    constructor() {
        super({
            key: SCENES.WIN
        })
    }
    init() {
    }
    preload() {
        this.load.atlas('megaset', 'assets/atlas/megaset-0.png', 'assets/atlas/megaset-0.json');
    }
    create() {
        this.background = this.add.image(0, 0, IMAGES.QUEST.key).setDepth(0);
        this.background.setOrigin(0, 0);
        this.background.scaleX = 0.3125;
        this.background.scaleY = 0.3225;

        
        let playButton = this.add.image(this.game.renderer.width / 2, this.game.renderer.height - 120, IMAGES.PLAY.key).setDepth(1);

        playButton.setInteractive();

        playButton.on("pointerup", () => {
            location.reload();
        })
        playButton.visible = false;

        this.add.text(this.game.renderer.width/2-80, 15, "You Won!", {
                fontSize: '35px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );

        const message = "Your code is " + WIN_NUMBERS[Phaser.Math.Between(0,199)];
        const codeText = this.add.text(this.game.renderer.width/2-70, 140, message, {
                fontSize: '15px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );
        codeText.visible = false;

        let particles = this.add.particles('spark');

        particles.createEmitter({
            x: 400,
            y: 450,
            angle: { min: 180, max: 360 },
            speed: 400,
            gravityY: 450,
            lifespan: 4000,
            quantity: 6,
            scale: { start: 0.1, end: 1 },
            blendMode: 'ADD'
        });

        const atlas = this.add.particles('megaset', [
            {
                frame: 'red_ball',
                x: 400,
                y: 590,
                angle: { min: 180, max: 360 },
                speed: 200,
                gravityY: -350,
                lifespan: 3000,
                quantity: 4,
                scale: { min: 0.1, max: 1 }
            },
            {
                frame: 'yellow_ball',
                x: 400,
                y: 590,
                angle: { min: 180, max: 360 },
                speed: 300,
                gravityY: -350,
                lifespan: 3000,
                quantity: 4,
                scale: { min: 0.1, max: 1 }
            },
            {
                frame: 'blue_ball',
                x: 400,
                y: 590,
                angle: { min: 180, max: 360 },
                speed: 400,
                gravityY: -350,
                lifespan: 3000,
                quantity: 4,
                scale: { min: 0.1, max: 1 }
            }
        ]);

        setTimeout(() => {
            atlas.destroy();
            playButton.visible = true;
            codeText.visible = true;
        }, 4000);

        setTimeout(() => {
            particles.destroy();
        }, 3000);

    }
}

export default WinScene;
