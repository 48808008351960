import Phaser from 'phaser';

import { SCENES, IMAGES, AUDIOS, SPRITES } from "../constants";


export class MenuScene extends Phaser.Scene {
    constructor() {
        super({
            key: SCENES.MENU
        })
    }
    init() {
    }
    create() {
        this.background = this.add.image(0, 0, IMAGES.QUEST.key).setDepth(0);
        this.background.setOrigin(0, 0);
        this.background.scaleX = 0.3125;
        this.background.scaleY = 0.3225;

        let playButton = this.add.image(this.game.renderer.width / 2, this.game.renderer.height - 120, IMAGES.PLAY.key).setDepth(1);

        playButton.setInteractive();

        playButton.on("pointerup", () => {
            this.scene.start(SCENES.PLAYER);
        })

    }
}

export default MenuScene;
