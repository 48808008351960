import Phaser from 'phaser';

import {
    SCENES, GAME_LENGTH, TIERS, BOOST_OBJECTS, ENEMY_OBJECTS, UI_OBJECTS, PLAYER_OBJECTS, IMAGES,
} from "../constants";

class PlayScene extends Phaser.Scene {
    constructor() {
        super({
            key: SCENES.PLAY
        })
        this.objects = [];
        this.enemies = [];
        this.enemySpeed = 4;
        this.player;
        this.currentStairs;
        this.playerSpeed = 8;

        // Is the player jumping
        this.isJumping = false;
        this.isCollidingStairs = false;
        this.isMovingBackward = false;
        
        // score
        this.score = 0;
        this.scoreString = "Score: ";
        this.scoreText;
        this.highScore;
        this.highScoreString = "High Score: ";
        this.highScoreText;

        //Introduction
        this.introText;
        // Game ended boolean
        this.finishedGame;
    }
    
    init(data) {
        this.selected_player = data.player;
    }

    // Start the game
    startGame() {
        setTimeout(() => {
            this.introText.visible = false;
        }, 2000);
        this.score = 0;
        this.scoreText.visible = true;
        this.highScoreText.visible = true;
        this.finishedGame = false;
    }

    // End the game
    gameOver() {
        this.introText.setText("Game Over! Click to play again.");
        this.finishedGame = true;
        this.introText.visible = true;
        //Set Highscore
        if(this.score > localStorage.getItem('sam_highscore')){
            localStorage.setItem('sam_highscore',this.score);
            this.highScore = this.score;
            this.highScoreText.setText(this.highScoreString + this.highScore);
        } 
        this.input.on("pointerdown", () => {
            this.cameras.main.fade(500);
            this.cameras.main.on('camerafadeoutcomplete', function(){
                this.scene.stop(SCENES.PLAY)
                location.reload();
            }, this);
        });
    }

    // Background
    setupBackground(){
        const {width, height} = this.game.config;
        this.background = this.add.tileSprite(0, 0, width * GAME_LENGTH, height, 'background');
        this.background.setOrigin(0, 0);
        this.background.scaleX = 1.4;
        this.background.scaleY = 1.4;

        // world bounds
        this.physics.world.bounds.width = width * GAME_LENGTH;
        this.physics.world.bounds.height = height;
    }

    //Ground
    setupGround(){
        // Add Ground
        const {width, height} = this.game.config;
        this.ground = this.add.tileSprite(0, height - 40, width * GAME_LENGTH, 30, 'upper_tile');
        this.lower_ground = this.add.tileSprite(0, height - 20, width * GAME_LENGTH, 30 , 'lower_tile');
        this.physics.add.existing(this.ground, true);
        this.physics.add.existing(this.lower_ground, true);
        this.ground.setOrigin(0, 0);
        this.lower_ground.setOrigin(0, 0);
        this.ground.body.updateFromGameObject();
        this.lower_ground.body.updateFromGameObject();
    }

    setupAnimations(){
        // Create animations for player
        this.anims.create({
            key: "idle",
            frames: this.anims.generateFrameNumbers(this.selected_player + "_attack", { start: 0, end: 0 })
        });
        this.anims.create({
            key: "move",
            frames: this.anims.generateFrameNumbers(this.selected_player + "_run", { start: 0, end: 5 }),
            repeat: -1,
            frameRate: this.playerSpeed
        });
        this.anims.create({
            key: "attack",
            frames: this.anims.generateFrameNumbers(this.selected_player + "_attack", { start: 0, end: 5 }),
            frameRate: this.playerSpeed*3
        });
        this.anims.create({
            key: "jump",
            frames: this.anims.generateFrameNumbers(this.selected_player + "_jump", { start: 0, end: 5 }),
            repeat: -1,
            frameRate: this.playerSpeed
        });
        this.anims.create({
            key: "hurt",
            frames: this.anims.generateFrameNumbers(this.selected_player + "_hurt", { start: 0, end: 2 }),
            repeat: -1,
            frameRate: this.playerSpeed
        });
        this.anims.create({
            key: "death",
            frames: this.anims.generateFrameNumbers(this.selected_player + "_death", { start: 0, end: 5 }),
            repeat: -1,
            frameRate: this.playerSpeed
        });
    }

    //Player, Player Frames, Gravity, player Ground Collision
    setupPlayer(){
        // Create player
        this.player = this.physics.add.sprite(80, this.game.config.height/2, this.selected_player + "_run");
        this.player.setOrigin(0, 0);
        this.player.scaleX = 2;
        this.player.scaleY = 2;
        const playerObj = PLAYER_OBJECTS[this.selected_player];
        this.player.body.setSize(this.player.width - playerObj.widthOffset, this.player.height - playerObj.heightOffset);
        this.player.body.setOffset(playerObj.widthOffset, playerObj.heightOffset);
        

        // camera bounds
        this.cameras.main.setBounds(0, 0, this.game.config.width * GAME_LENGTH, this.game.config.height);
        this.cameras.main.startFollow(this.player, false, 1, 1, -250);

        //Player colliding walls
        this.player.setCollideWorldBounds(true);
        //Set Gravity to the player
        this.player.setGravityY(700);

        //Add Collider
        this.physics.add.collider(this.player, this.ground, () => {
            this.isJumping = false;
        });
    }

    //Add Game to the game
    setupGame(){
        for (let i = 1; i < GAME_LENGTH - 1; i++) {
            this.addUI(i);
            this.addUI(i);
            this.addBoost(i);
            this.addBoost(i);
            this.addBoost(i);
            if(Phaser.Math.Between(1,5) == 2) {
                this.addStairs(i);
            } else {
                this.addEnemy(i);
                this.addEnemy(i);
                this.addEnemy(i);
                this.addEnemy(i);
            }
            this.addEnemy(i);
        }
        this.addFlag();
    }

    addStairs(index) {
        let x = Phaser.Math.Between(this.game.config.width*index, this.game.config.width*(index+1));

        const stairs = this.physics.add.group({
            immovable: true,
            allowGravity: false,
        });

        const width_offset = 32;
        const height_offset = 24;
        const tier_offset = 28;
        const stair_length = Phaser.Math.Between(7, 10);

        for(let i = 0; i < stair_length; i++) {
            for(let j = i; j < stair_length; j++) {
                const stair = this.add.sprite(x + width_offset * j, TIERS[0] + tier_offset - height_offset * i, "box");
                this.physics.add.existing(stair);
                stairs.add(stair);
            }
        }

        stairs.maxX = x + width_offset * stair_length;

        this.physics.add.collider(this.player, stairs, () => {
            this.isCollidingStairs = true;
            this.isJumping = false;
            this.currentStairs = stairs;
        });
    }

    addFlag() {
        //Initial direction pointer
        this.add.image(50, TIERS[0] + 30, IMAGES.POINTER.key).setDepth(0);

        this.flag = this.physics.add.sprite(this.game.config.width*GAME_LENGTH - 70, TIERS[0], "flag");
        this.flag.scaleX = 3;
        this.flag.scaleY = 3;
        this.flag.anims.play("flag");
        this.flag.setImmovable(true);
        
        //Add Collider
        this.physics.add.collider(this.player, this.flag, () => {
            this.finishedGame = true;
            this.scene.stop(SCENES.PLAY)
            this.scene.start(SCENES.WIN)
        });
    }

    addUI(index) {
        let x = Phaser.Math.Between(this.game.config.width*index, this.game.config.width*(index+1));
        let randObj = UI_OBJECTS[Phaser.Math.Between(0, UI_OBJECTS.length - 1)];
        let object = this.add.image(x, TIERS[randObj.tier] + randObj.offset, randObj.name).setDepth(0);

        object.scaleX = 2;
        object.scaleY = 2;
        this.objects.push(object);
    }

    addBoost(index) {
        let x = Phaser.Math.Between(this.game.config.width*index, this.game.config.width*(index+1));
        const randObj = BOOST_OBJECTS[Phaser.Math.Between(0, BOOST_OBJECTS.length - 1)];
        const { name, tier, widthOffset, heightOffset, heightAdjustSize, offset } = randObj;
        let object = this.physics.add.sprite(x, TIERS[tier] + offset, name);

        object.scaleX = 2;
        object.scaleY = 2;
        object.body.setSize(object.width - widthOffset, object.height - heightOffset - heightAdjustSize);
        object.body.setOffset(widthOffset, heightOffset);
        object.anims.play(name);
        object.setImmovable(true);
        this.objects.push(object);

        //Add Collider
        this.physics.add.collider(this.player, object, () => {
            if(this.finishedGame){
                return;
            }
            this.score += 1000;
            object.destroy();
        });
    }

    addEnemy(index) {
        let x = Phaser.Math.Between(this.game.config.width*index, this.game.config.width*(index+1));
        const randObj = ENEMY_OBJECTS[Phaser.Math.Between(0, ENEMY_OBJECTS.length - 1)];
        const { name, tier, widthOffset, heightOffset } = randObj;
        let object = this.physics.add.sprite(x, TIERS[tier], name + "_walk");

        object.scaleX = 2;
        object.scaleY = 2;
        object.body.setSize(object.width - widthOffset, object.height - heightOffset);
        object.body.setOffset(widthOffset, heightOffset);
        object.setImmovable(true);
        object.randConfig = randObj;
        this.enemies.push(object);

        //Add Collider
        this.physics.add.collider(this.player, object, () => {
            if(this.finishedGame){
                return;
            }
            if (this.player.anims.getName() == "attack") {
                object.deactivate = true;
                this.score += 1000;
                this.scoreText.setText(this.scoreString + this.score);
            }else {
                this.player.anims.play("hurt", true);
                this.gameOver();
                setTimeout(() => {
                    this.player.anims.play("death", true);
                }, 500);
                setTimeout(() => {
                    this.player.destroy();
                }, 1000);
            }
        });
    }

    //Move the Enemy and Update the score
    moveEnemy(E) {
        if(Math.abs(E.x - this.player.x) < 200) {
            E.anims.play(E.randConfig.name + "_attack", true);
        }
        if(E.x < 0){
            E.deactivate = true; 
        }else if (Math.abs(E.x - this.player.x) < 500){
            E.x -= (this.enemySpeed - Phaser.Math.Between(1,2));
            E.anims.play(E.randConfig.name + "_walk", true);
        }
    }

    setupText(){
        //Get Highscore
        if(!localStorage.getItem('sam_highscore')){
            this.highScore = 0;
        }else{
            this.highScore = localStorage.getItem('sam_highscore');
        }
        // Generate text for High Score
        this.highScoreText = this.add.text(3*this.game.config.width/4,  24,  this.highScoreString + this.highScore, {
                fontSize: '17px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );
        // Generate text for score
        this.scoreText = this.add.text(3*this.game.config.width/4-150,  24,  this.scoreString + this.score, {
                fontSize: '17px',
                fontFamily: 'Arial',
                color: '#000',
                fontStyle:'bold'
            }
        );
        // Generate intro text
        this.introText = this.add.text(82, 84, "Use space to attack Enemies.", {
                fontSize: '15px',
                fontFamily: 'Arial',
                color: '#ffffff',
                align: 'center',
            }
        );

        this.scoreText.visible = false;
        this.highScoreText.visible = false;

        this.scoreText.scrollFactorX = 0;
        this.highScoreText.scrollFactorX = 0;
        this.introText.scrollFactorX = 0;
    }

    //Cursor and game start click
    setupCursors(){
        // Keyboard input
        this.cursors = this.input.keyboard.createCursorKeys();        
    }

    //PHASER create function
    create() {
        this.setupBackground();
        this.setupAnimations();
        this.setupGround();
        this.setupPlayer();
        this.setupText();
        this.setupCursors();
        this.setupGame();
        this.startGame();
    }  

    //PHASER update function
    update() {
        //Move Enemies
        for (var i = 1; i < this.enemies.length; i++) {
            if(this.enemies[i]){
                this.moveEnemy(this.enemies[i]);
            }
            if(this.enemies[i].deactivate){
                this.enemies[i].destroy();
                this.enemies.splice(i, 1);
            }
        }

        if (!this.finishedGame) {
            let idle = true;
            //Jump Event
            if (this.cursors.up.isDown && !this.isJumping) {
                // Move up
                this.player.setVelocityY(-450);
                this.player.anims.play("jump");
                this.isJumping = true;
                idle = false;
            }
            if(this.cursors.space.isDown){
                this.player.anims.play("attack", true);
                idle = false;
            }
            if(this.cursors.right.isDown){
                if (!this.isCollidingStairs){
                    this.score += 1;
                    this.scoreText.setText(this.scoreString + this.score);
                    this.player.x += this.playerSpeed/2;
                    this.player.anims.play("move", true);
                    idle = false;
                    if(this.isMovingBackward) {
                        this.isMovingBackward = false;
                        this.player.flipX = false;
                    }
                    if(this.currentStairs && this.player.x + this.player.width + 20 > this.currentStairs.maxX) {
                        this.currentStairs.clear(true, true);
                        this.currentStairs = null;
                    }
                } else {
                    this.isCollidingStairs = false;
                }
            }
            if(this.cursors.left.isDown){
                if (!this.isCollidingStairs){
                    this.score -= 1;
                    this.scoreText.setText(this.scoreString + this.score);
                    this.player.x -= this.playerSpeed/2;
                    this.player.anims.play("move", true);
                    idle = false;
                    if(!this.isMovingBackward) {
                        this.isMovingBackward = true;
                        this.player.flipX = true;
                    }
                } else {
                    this.isCollidingStairs = false;
                }
            }
            if(!this.isJumping && idle) {
                this.player.anims.play("idle", true);
            }
        }
    }
}

export default PlayScene;